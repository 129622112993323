import { Treasury } from '@cgentium/arcade-ts';

export const IDL: Treasury = {
  version: '0.1.0',
  name: 'treasury',
  instructions: [
    {
      name: 'initTreasury',
      accounts: [
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'arcMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMetadata',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMinter',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'splProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mplProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'bene',
          type: {
            vec: 'publicKey',
          },
        },
        {
          name: 'splits',
          type: {
            vec: 'u32',
          },
        },
        {
          name: 'splitShareholders',
          type: 'u32',
        },
        {
          name: 'sale',
          type: 'publicKey',
        },
        {
          name: 'salePrg',
          type: 'publicKey',
        },
        {
          name: 'arcMintBump',
          type: 'u8',
        },
        {
          name: 'arcMinterBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'buyArc',
      accounts: [
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'owner',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'caller',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'callerAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMinter',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'splProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'lamps',
          type: 'u64',
        },
        {
          name: 'treasuryBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'deposit',
      accounts: [
        {
          name: 'from',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'owner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'fromAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'treasuryAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'splProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'narcs',
          type: 'u64',
        },
        {
          name: 'memo',
          type: 'string',
        },
        {
          name: 'treasuryBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'claimShare',
      accounts: [
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'signerAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'nftMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'nftAta',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'treasuryAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'owner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'arcMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'splProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'treasuryBump',
          type: 'u8',
        },
        {
          name: 'shareix',
          type: 'u64',
        },
      ],
    },
    {
      name: 'resetBuyLimit',
      accounts: [
        {
          name: 'owner',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'newLimit',
          type: 'u64',
        },
        {
          name: 'newOnce',
          type: 'u64',
        },
        {
          name: 'treasuryBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'setRewardTierCost',
      accounts: [
        {
          name: 'owner',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tier',
          type: 'u8',
        },
        {
          name: 'newCost',
          type: 'u32',
        },
        {
          name: 'treasuryBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'claimRewardToken',
      accounts: [
        {
          name: 'owner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'treasury',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'rewardHolder',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'signerRewAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signerArcAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'rewAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'arcMint',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'rewMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'splProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tier',
          type: 'u8',
        },
        {
          name: 'rewardHolderBump',
          type: 'u8',
        },
        {
          name: 'treasuryBump',
          type: 'u8',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'treasuryInfo',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'holdings',
            type: {
              vec: 'u32',
            },
          },
          {
            name: 'bene',
            type: {
              vec: 'publicKey',
            },
          },
          {
            name: 'splits',
            type: {
              vec: 'u32',
            },
          },
          {
            name: 'splitShareholders',
            type: 'u32',
          },
          {
            name: 'sale',
            type: 'publicKey',
          },
          {
            name: 'salePrg',
            type: 'publicKey',
          },
          {
            name: 'owner',
            type: 'publicKey',
          },
          {
            name: 'arcSaleLeft',
            type: 'u64',
          },
          {
            name: 'arcSaleOnce',
            type: 'u64',
          },
          {
            name: 'rewtierCosts',
            type: {
              vec: 'u32',
            },
          },
          {
            name: 'bumpArcMint',
            type: 'u8',
          },
          {
            name: 'bumpArcMinter',
            type: 'u8',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'ErrorCodes',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'InvalidMint',
          },
          {
            name: 'InvalidAta',
          },
          {
            name: 'InvalidMintAuthority',
          },
          {
            name: 'InternalError',
          },
          {
            name: 'InvalidSeller',
          },
          {
            name: 'IncorrectBeneCount',
          },
          {
            name: 'InvalidArg',
          },
          {
            name: 'AmountsNotAddingUp',
          },
          {
            name: 'NotOwningNft',
          },
          {
            name: 'SupplyExceeded',
          },
          {
            name: 'Unauthorized',
          },
          {
            name: 'InvalidTreasury',
          },
          {
            name: 'InvalidRewardTier',
          },
        ],
      },
    },
  ],
};
