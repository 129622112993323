import React, { useState, useMemo, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import { Context } from 'context';
import './App.css';
import Game from 'pages/Game';
import OwnerHq from 'pages/OwnerHq';
import Login from 'pages/Login';
import { TreasuryAddon } from '@cgentium/arcade-ts';
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react';
import { AnchorProvider, Program } from '@project-serum/anchor';
import * as web3 from '@solana/web3.js';
import { IDL } from 'utils';
import { BackendAddon } from '@cgentium/arcade-ts';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import GameDemo from 'pages/GameDemo';
import MainLayout from 'pages/MainLayout/MainLayout';
import Story from 'pages/Story';
import Cast from 'pages/Cast';
import Mint from 'pages/Mint';
import Faux from 'pages/Faux';
import Arcade from 'pages/Arcade';
import Faq from 'pages/Faq';

const CUSTODY = new web3.PublicKey(process.env.REACT_APP_CUSTODY as string);
const PROGRAM_ID = new web3.PublicKey(
  process.env.REACT_APP_PROGRAM_ID as string
);
const OWNER = new web3.PublicKey(process.env.REACT_APP_OWNER as string);
const URL = process.env.REACT_APP_URL || '';

interface IProtectedRoute {
  isLogin: boolean;
  children: JSX.Element;
}

const ProtectedRoute = ({ isLogin, children }: IProtectedRoute) => {
  if (!isLogin) {
    return <Navigate to='/owner-hq/login' replace />;
  }
  return children;
};

function AppRouter() {
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = () => {
    setIsLogin(true);
  };

  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const { setVisible } = useWalletModal();
  const { connected } = useWallet();

  useEffect(() => {
    if (!connected) setVisible(true);
    else setVisible(false);
  }, [connected, setVisible]);

  const treasury = useMemo(() => {
    if (wallet) {
      const provider = new AnchorProvider(connection, wallet, {});
      const programId = new web3.PublicKey(PROGRAM_ID);
      const program = new Program(IDL, programId, provider);
      return new TreasuryAddon(provider, program, OWNER);
    }
  }, [connection, wallet]);

  useEffect(() => {
    calcAcc();
  }, [treasury]);

  const calcAcc = async () => {
    setIsLoading(true);
    try {
      if (treasury) await treasury.calcAccsFromOwner();
    } catch (error) {}
    setIsLoading(false);
  };

  const backendAddon = useMemo(() => {
    if (wallet && treasury) {
      return new BackendAddon(URL, CUSTODY, treasury.arcMint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treasury, treasury?.arcMint, wallet]) as BackendAddon;

  return (
    <Routes>
      <Route path='/' element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path='arcade' element={<Arcade />} />
        <Route path='the-story' element={<Story />} />
        <Route path='the-cast' element={<Cast />} />
        <Route path='faq' element={<Faq />} />
        <Route path='faux-purchase' element={<Faux />} />
      </Route>
      <Route
        path='/owner-hq'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <OwnerHq />
          </ProtectedRoute>
        }
      />
      <Route
        path='/owner-hq/login'
        element={
          <Login
            isLoading={isLoading}
            onLogin={onLogin}
            backendAddon={backendAddon}
          />
        }
      />
      <Route path='/game' element={<GameDemo />} />
    </Routes>
  );
}

const App = () => {
  return (
    // <Context>
    <AppRouter />
    // </Context>
  );
};

export default App;
