import React, { useRef } from 'react';
import arcade1 from 'assets/new/arcade1.png';
import arcade2 from 'assets/new/arcade2.png';
import arcade3 from 'assets/new/arcade3.png';
import arcade4 from 'assets/new/arcade4.png';
import faq from 'assets/new/faq.png';
import minting from 'assets/new/minting.png';
import scrolldown from 'assets/new/scrolldown.png';

const listArcade = [
  {
    img: arcade1,
    title: 'Daily Free Play in the Arcade',
  },
  {
    img: arcade2,
    title: 'Players buy $FOX token to play Arcade to accumulate more $FOX',
  },
  {
    img: arcade3,
    title: 'Fixed number of $FOX token will be released each day',
  },
  {
    img: arcade4,
    title: 'Accumulate $FOX to claim rewards',
  },
];

export default function Arcade() {
  const myRef = useRef<null | HTMLImageElement>(null);

  const executeScroll = () => {
    if (myRef.current && myRef)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='bg-arcadebgMobile md:hidden md:bg-arcadebg bg-no-repeat bg-cover min-h-screen font-AnybodyBold px-[50px] md:px-[100px] pt-[70px] md:pt-0 pb-[140px] z-50'>
      <div>
        <div className='pt-[15%] 2xl:pt-[13%]'>
          <p className='md:text-center md:w-[80%] mx-auto text-[15px] md:text-[20px] text-justify'>
            First things first: if you're new to the world of $FOX, let us
            explain how it works. The FauxArcade works by using the SOL token.
            You can buy $FAUX using SOL or earn them through playing games. Once
            you have enough $FAUX, you can redeem them for really cool prizes
            like a pretty kwool NFT at the Faux Marketplace.{' '}
            <p className='block mt-[10px] md:mt-0 md:inline'>
              It is a new way to win, and it's only available at FauxArcade.
            </p>
          </p>
        </div>
        <div className='mt-[35px] md:hidden'>
          <img
            src={scrolldown}
            alt='scrolldown'
            className='w-[31px] mx-auto'
            onClick={executeScroll}
          />
        </div>
        <div
          className='grid grid-cols-1 md:grid-cols-4 gap-20 md:mt-[5%] 2xl:mt-[7%] 2xl:max-w-[85%] mx-auto pt-[75px]'
          ref={myRef}
        >
          {listArcade.map((item, index) => (
            <div key={index} className='flex flex-col items-center'>
              <div className='md:h-[280px] w-[215px] md:w-auto flex items-end'>
                <img src={item.img} alt='arcade' className='w-full' />
              </div>
              <p className='mt-[10px] md:mt-[40px] text-[20px] text-center leading-[25px] md:text-justify'>
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
