import React, { useMemo, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
// import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import logo from '../../assets/new/logo.png';
import { AiOutlineMenu } from 'react-icons/ai';
import styles from './Header.module.css';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import comingSoon from 'assets/new/coming-soon-nav.png';
// import comingSoonMobile from 'assets/new/coming-soon-mobile.png';

const listNavLeft = [
  {
    name: 'ARCADE',
    path: '/arcade',
    isComingSoon: true,
  },
  {
    name: 'THE STORY',
    path: '/the-story',
  },
  {
    name: 'THE CAST',
    path: '/the-cast',
  },
];

const listNavRight = [
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'FAUX PURCHASE',
    path: '/faux-purchase',
    isComingSoon: true,
  },
];

interface ICustomLink extends LinkProps {
  toggleMenu: () => void;
  isComingSoon?: boolean;
}

function CustomLink({
  children,
  to,
  toggleMenu,
  isComingSoon,
  ...props
}: ICustomLink) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <>
      {isComingSoon ? (
        <li
          className={`text-[20px] md:text-[29px] 2xl:md:text-[30px] text-white`}
        >
          {children}
        </li>
      ) : (
        <Link to={to} {...props} onClick={toggleMenu}>
          <li
            className={`text-[20px] md:text-[29px] 2xl:md:text-[30px] hover:text-[#400081] ${
              match ? 'text-[#400081]' : 'text-white'
            }`}
          >
            {children}
          </li>
        </Link>
      )}
    </>
  );
}

interface IHeaderProps {
  onToggle?: () => void;
}

export default function Header({ onToggle }: IHeaderProps) {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => setOpenMenu(!openMenu);

  const { publicKey, wallet, connected } = useWallet();

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const walletAddress = useMemo(() => {
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + '...' + base58.slice(-4);
  }, [wallet, base58]);

  return (
    <header className='fixed top-0 left-0 right-0 w-full h-[55px] md:h-[62px] mx-auto flex justify-between px-5 md:px-8 2xl:px-10 bg-[#F4911D] font-Koulen py-1 xl:py-0 z-50'>
      <div className='w-full flex items-center justify-end md:justify-between xl:relative'>
        <ul
          className={`grow xl:flex xl:justify-between items-center absolute z-50 left-0 right-0 px-3 py-2 xl:py-0 ${
            styles.menu
          } xl:visible xl:opacity-100 xl:static xl:translate-y-0 ${
            openMenu && styles.dropdown
          }`}
        >
          <div className='xl:flex gap-3 md:gap-[75px] flex-1 xl:justify-end relative'>
            {listNavLeft.map((item, index) => (
              <CustomLink
                key={index}
                to={item.path}
                toggleMenu={toggleMenu}
                isComingSoon={item.isComingSoon}
              >
                {item.name}
                {item.isComingSoon ? (
                  <img
                    src={comingSoon}
                    alt='comming soon'
                    className='absolute w-[50px] top-[14px] left-[27px] md:w-[70px] md:left-[40px] md:top-[17px] xl:w-[123px] xl:top-[27px] xl:left-auto xl:right-[300px]'
                  />
                ) : null}
              </CustomLink>
            ))}
          </div>
          <div className='w-[235px] mx-8'></div>
          <Link to='/' onClick={() => setOpenMenu(false)}>
            <img
              src={logo}
              alt='logo'
              className='absolute top-0 left-0 right-0 mx-auto cursor-pointer w-[100px] xl:w-[256px] hidden xl:inline'
            />
          </Link>
          <div className='xl:flex gap-3 md:gap-[75px] flex-1 xl:justify-start mr-[-108px] relative'>
            {listNavRight.map((item, index) => (
              <CustomLink
                key={index}
                to={item.path}
                toggleMenu={toggleMenu}
                isComingSoon={item.isComingSoon}
              >
                {item.name}
                {item.isComingSoon ? (
                  <img
                    src={comingSoon}
                    alt='comming soon'
                    className='absolute w-[50px] bottom-[-5px] left-[83px] md:w-[70px] md:left-[155px] xl:w-[123px] xl:top-[27px] xl:left-[190px]'
                  />
                ) : null}
              </CustomLink>
            ))}
          </div>
        </ul>
        <AiOutlineMenu
          className='text-[30px] md:text-[40px] xl:hidden'
          onClick={toggleMenu}
        />
        <Link to='/' onClick={() => setOpenMenu(false)}>
          <img
            src={logo}
            alt='logo'
            className='absolute top-1 left-0 right-0 mx-auto cursor-pointer w-[150px] md:top-3 xl:w-auto xl:hidden z-50'
          />
        </Link>
        <div className='md:flex gap-7 hidden'>
          {/* <a
            href='https://discord.gg/EmfCfBk4QS'
            target='_blank'
            rel='noreferrer'
            className='z-50'
          > */}
          <FaDiscord className='text-[25px] md:text-[40px] hover:cursor-pointer text-gray-600' />
          {/* </a> */}
          <a
            href='https://twitter.com/fauxarcade'
            target='_blank'
            rel='noreferrer'
            className='z-50'
          >
            <FaTwitter className='text-[25px] md:text-[40px] hover:cursor-pointer hover:text-[#400081]' />
          </a>
        </div>
      </div>
    </header>
  );
}
