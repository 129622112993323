import Modal from 'components/Modal';
import React from 'react';
import LootBox from '../../assets/new/Lootbox.gif';
import LootBoxImage from '../../assets/new/Lootbox.png';
import close from '../../assets/new/close.png';
import './styles.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

export default function GameDemo() {
  const [isOpenGif, setIsOpenGif] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openGif = () => {
    setIsOpenGif(true);
    setTimeout(() => {
      setIsOpenModal(true);
      setIsOpenGif(false);
    }, 3300);
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsOpenGif(false);
  };

  return (
    <div className='flex flex-col justify-center items-center bg-black min-h-screen text-white'>
      <h1 className='text-[70px] font-bold text-center leading-[75px]'>
        Open Looties to win up <br /> to 100X in SOL
      </h1>
      <div
        className='border border-[#434343] p-4 box rounded-[10px] mt-12 cursor-pointer'
        onClick={openGif}
      >
        <img
          src={LootBoxImage}
          alt='LootBoxImage'
          className='w-[400px] h-auto rounded-[10px]'
        />
        {/* <div className='h-[50px]'></div> */}
      </div>
      {isOpenGif && (
        <>
          <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black opacity-80 z-0'></div>
          <div className='fixed left-0 right-0 m-auto w-[90%] md:w-[900px] bg-black px-5 py-[30px] md:px-[70px] rounded-[36px] text-white text-center text-[18px] z-20'>
            <img src={LootBox} alt='LootBox' className='rounded-[10px]' />
          </div>
        </>
      )}
      <MyModal isOpen={isOpenModal} onClose={onClose} />
    </div>
  );
}

interface IMyModal {
  isOpen: boolean;
  onClose: () => void;
}

function MyModal({ isOpen, onClose }: IMyModal) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  You got a gift
                </Dialog.Title>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    You got a free gift from us. We’ve sent you an email with
                    all of the details of your information.
                  </p>
                </div>

                <div className='mt-4'>
                  <button
                    type='button'
                    className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    onClick={onClose}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
