import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react';
import { BackendAddon } from '@cgentium/arcade-ts';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { postLoginByDeposit, postLoginByDepositPaid } from 'apis';
import { AnchorProvider } from '@project-serum/anchor';
import Loading from '../../components/Loading';

interface ILogin {
  onLogin: () => void;
  backendAddon: BackendAddon;
  isLoading: boolean;
}

export default function Login({ onLogin, backendAddon, isLoading }: ILogin) {
  const navigate = useNavigate();

  const { connected } = useWallet();
  const { setVisible } = useWalletModal();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();

  const onLoginBySign = async () => {
    if (connected) {
      const memo = backendAddon.generateMemo(window.crypto);
      try {
        await backendAddon.loginBySignature(memo);
        onLogin();
        navigate('../owner-hq');
      } catch (error) {
        console.log(error);
      }
    } else setVisible(true);
  };

  async function loginByDeposit() {
    try {
      const memo = backendAddon.generateMemo(window.crypto);
      const response: IResponseLoginByDeposit = await postLoginByDeposit({
        memo,
      });
      if (wallet) {
        const provider = new AnchorProvider(connection, wallet, {});
        const tx = await backendAddon.depositToCustody(
          provider,
          [],
          memo,
          BigInt(3000000)
        );
        await postLoginByDepositPaid({
          txid: tx,
          session: response.data.session,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-full h-screen'>
        <div className='fixed top-20 md:top-[150px] bg-white left-0 right-0 m-auto w-[90%] md:w-[450px] px-5 py-[30px] md:px-[70px] rounded-[36px] text-center text-[18px] shadow-2xl'>
          <h1 className='text-[30px] font-bold'>Login</h1>
          <p className='my-5'>Please login to access admin page</p>
          <div className='flex flex-col'>
            <button
              type='button'
              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
              onClick={onLoginBySign}
            >
              {connected ? 'Login by sign' : 'Connect Wallet'}
            </button>
            <button
              type='button'
              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
              onClick={loginByDeposit}
            >
              Login by deposit
            </button>
          </div>
        </div>
      </div>
      {isLoading && <Loading show={isLoading} />}
    </>
  );
}
