import useScrollTop from 'hooks/useScrollTop';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';

export default function MainLayout() {
  useScrollTop();

  return (
    <div className='text-white font-Overpass'>
      <Header />
      <Outlet />
    </div>
  );
}
