import React, { useMemo } from 'react';
import { TreasuryAddon } from '@cgentium/arcade-ts';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { AnchorProvider, Program } from '@project-serum/anchor';
import * as web3 from '@solana/web3.js';
import { IDL } from 'utils';
import { BN } from '@project-serum/anchor';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Nav from './Nav';
import SideBar from './SideBar';
import Table from './Table';
import Growth from './Growth';

const PROGRAM_ID = new web3.PublicKey(
  '9F5FPHxPsLqDmxGpceUEHTN4a9oH6Ubs9YDQpvUXhb9L'
);

export default function OwnerHq() {
  const { connection } = useConnection();
  const wallet = useAnchorWallet();

  const treasury = useMemo(() => {
    if (wallet) {
      const provider = new AnchorProvider(connection, wallet, {});
      const programId = new web3.PublicKey(PROGRAM_ID);
      const program = new Program(IDL, programId, provider);
      return new TreasuryAddon(provider, program, wallet.publicKey);
    }
  }, [connection, wallet]);

  const formik = useFormik({
    initialValues: {
      newLimit: 0,
      newOnce: 0,
    },
    validationSchema: Yup.object({
      newLimit: Yup.number()
        .positive('Must be more than 0')
        .required('Must be more than 0')
        .moreThan(0, 'Must be more than 0'),
      newOnce: Yup.number()
        .positive('Must be more than 0')
        .required('Must be more than 0')
        .moreThan(0, 'Must be more than 0'),
    }),
    onSubmit: (values) => {
      console.log('🚀 ~ file: index.tsx ~ line 40 ~ OwnerHq ~ values', values);
      const newLimit = new BN(values.newLimit);
      const newOnce = new BN(values.newOnce);
      const signers: any[] = [];
      treasury?.resetBuyLimit(newLimit, newOnce, signers);
    },
  });

  return (
    <div>
      <Nav />
      <div className='flex overflow-hidden bg-white pt-16'>
        <SideBar />
        <div className='h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64 p-10'>
          <div className='bg-white shadow rounded-lg mb-4 p-4 sm:p-6 h-full'>
            <div className='flex items-center justify-between mb-4'>
              <h3 className='text-xl font-bold leading-none text-gray-900'>
                Reset Buy Limit
              </h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className='grid gap-6 mb-6 grid-cols-3'>
                <div>
                  <label
                    htmlFor='newLimit'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  >
                    New Limit
                  </label>
                  <input
                    id='newLimit'
                    name='newLimit'
                    type='number'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder='New Limit'
                    onChange={formik.handleChange}
                    value={formik.values.newLimit}
                  />
                  {formik.errors.newLimit && formik.touched.newLimit && (
                    <p className='text-sm text-red-500'>
                      {formik.errors.newLimit}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='newOnce'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  >
                    New Once
                  </label>
                  <input
                    id='newOnce'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder='New Once'
                    name='newOnce'
                    type='number'
                    onChange={formik.handleChange}
                    value={formik.values.newOnce}
                  />
                  {formik.errors.newOnce && formik.touched.newOnce && (
                    <p className='text-sm text-red-500'>
                      {formik.errors.newOnce}
                    </p>
                  )}
                </div>
              </div>
              <button
                type='submit'
                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
