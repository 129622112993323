import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://rusty-peon.dev.cgentium.com',
});

// export const loginBySignRequest = (data: ILoginRequest) => {
//   return instance.post('/login/bysig', data);
// };

export const spinWheel = (data: IRequestSpinWheel) => {
  return instance.post('/play/spin', data);
};

export const postLoginByDeposit = (data: IRequestLoginByDeposit) => {
  return instance.post('/login/bydeposit', data);
};

export const postLoginByDepositPaid = (data: IRequestLoginByDepositPaid) => {
  return instance.post('/login/bydeposit/paid', data);
};
