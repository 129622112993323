import React from 'react';
import faux from '../../assets/new/faux.png';
import chayka1 from '../../assets/new/chayka1.png';
import chayka2 from '../../assets/new/chayka2.png';
import chayka3 from '../../assets/new/chayka3.png';
import chayka4 from '../../assets/new/chayka4.png';
import chayka5 from '../../assets/new/chayka5.png';
import chayka6 from '../../assets/new/chayka6.png';
import chayka7 from '../../assets/new/chayka7.png';

const listChayka = [
  {
    img: chayka1,
    name: 'GILGAMESH',
    content: 'DID FINANCE SHIT for big corpS. Now owns an Arcade',
  },
  {
    img: chayka2,
    name: 'CHRONOS',
    content: 'AVID BLOCKCHAIN EXPLORER. DOESN’T LIKE FURRY JPEGS',
  },
  {
    img: chayka3,
    name: 'GRID',
    content: 'Michael Burry of the Aptos market',
  },
  {
    img: chayka4,
    name: 'MR S',
    content: 'GAME DESIGNER AND UNEMPLOYED COUCH POTATO',
  },
  {
    img: chayka5,
    name: 'MIDAS',
    content: 'WORDSMITH WITH A FAUX FETISH',
  },
  {
    img: chayka6,
    name: 'Kogarashi',
    content: 'CHILLIN AND VIBING. FINDING WAYS TO HYPE UP FAUXES',
  },
  {
    img: chayka7,
    name: 'TAIJITU',
    content: 'NIGHT-TIME DESIGNER AND PLANNER OF THE ARCADE VIBEZ',
  },
];

export default function Cast() {
  return (
    <div className='bg-castbgMobile md:bg-castbg bg-cover bg-no-repeat w-full h-full pt-[13%] pb-[200px] flex flex-col justify-center relative'>
      <div className='hidden md:block'>
        <img src={faux} alt='faux' className='mx-auto' />
      </div>
      <div className='flex flex-col gap-10 mt-[80px] px-5 lg:px-10 xl:px-24'>
        <div className='flex justify-center flex-wrap xl:flex-nowrap gap-[10%] gap-y-10'>
          {[listChayka[0], listChayka[1]].map((item, index) => (
            <div
              key={index}
              className='p-[8px] pb-[15px] md:pb-[30px] bg-[#FFF3E5] w-[200px] md:w-[307px] fauxBoxShadow font-Koulen'
            >
              <img src={item.img} alt='chayka1' className='w-full' />
              <p className='text-[30px] md:text-[40px] text-black text-center uppercase'>
                {item.name}
              </p>
              <p className='text-black text-[13px] md:text-[25px] text-center leading-[14px] md:leading-[25px] whitespace-pre-line px-3 xl:px-0'>
                {item.content}
              </p>
            </div>
          ))}
        </div>
        <div className='flex justify-center flex-wrap xl:flex-nowrap gap-[10%] gap-y-10'>
          {[listChayka[3], listChayka[4], listChayka[6]].map((item, index) => (
            <div
              key={index}
              className='p-[8px] pb-[15px] md:pb-[30px] bg-[#FFF3E5] w-[200px] md:w-[307px] fauxBoxShadow font-Koulen'
            >
              <img src={item.img} alt='chayka1' className='w-full' />
              <p className='text-[30px] md:text-[40px] text-black text-center uppercase'>
                {item.name}
              </p>
              <p className='text-black text-[13px] md:text-[25px] text-center leading-[14px] md:leading-[25px] whitespace-pre-line px-3 xl:px-0'>
                {item.content}
              </p>
            </div>
          ))}
        </div>
        <div className='flex justify-center flex-wrap xl:flex-nowrap gap-[10%] gap-y-10'>
          {[listChayka[5], listChayka[2]].map((item, index) => (
            <div
              key={index}
              className='p-[8px] pb-[15px] md:pb-[30px] bg-[#FFF3E5] w-[200px] md:w-[307px] fauxBoxShadow font-Koulen'
            >
              <img src={item.img} alt='chayka1' className='w-full' />
              <p className='text-[30px] md:text-[40px] text-black text-center uppercase'>
                {item.name}
              </p>
              <p className='text-black text-[13px] md:text-[25px] text-center leading-[14px] md:leading-[25px] whitespace-pre-line px-3 xl:px-0'>
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
