import React, { useState } from 'react';
import Header from 'components/Header/Header';
import banner from 'assets/new/banner.png';
import comingSoon from 'assets/new/coming-soon.png';
import aptos from 'assets/new/aptos.png';
import discord from 'assets/new/discord.png';
import twitter from 'assets/new/twitter.png';
import comingSoonRight from 'assets/new/coming-soon-right.png';
import { Link } from 'react-router-dom';
import bannerText from 'assets/new/banner-mobile-text.png';
import homeBox1 from 'assets/new/home-box-1.png';
import homeBox2 from 'assets/new/home-box-2.png';
import homeBox3 from 'assets/new/home-box-3.png';
import homeBoxActive from 'assets/new/home-box-active.png';

export default function Home() {
  const [isShownLeft, setIsShownLeft] = useState(false);
  const [isShownRight, setIsShownRight] = useState(false);

  return (
    <div className='bg-bannerMobile mt-[50px] md:mt-0 md:bg-none bg-no-repeat bg-cover bg-center h-screen'>
      <Header />
      <div className='relative hidden md:block'>
        <img src={banner} alt='banner' className='w-full h-screen' />
        <div className='absolute top-[37%] left-[24%] z-30 group w-[18%] h-[34%] flex items-center'>
          <img
            src={comingSoon}
            alt='comingSoon'
            className={`group-hover:block w-full scale-[1.5] mt-[12%] ${
              isShownLeft ? 'block' : 'hidden'
            }`}
          />
        </div>
        <div className='absolute top-[43%] right-[17%] z-30 group w-[18%] h-[34%] flex items-end'>
          <img
            src={comingSoonRight}
            alt='comingSoon'
            className={`group-hover:block w-full scale-[1.5] mb-[10%] ${
              isShownRight ? 'block' : 'hidden'
            }`}
          />
        </div>
        <Link to={'/faq'}>
          <div className='absolute top-[20%] right-0 left-0 mx-auto z-30 group w-[11%] h-[60%]'></div>
        </Link>
        <div className='hidden md:flex justify-center gap-8 xl:gap-24 absolute bottom-14 mx-auto left-0 right-0'>
          <img
            src={homeBox1}
            alt='home box'
            className='md:scale-75 lg:scale-100 w-[264px] group-hover:hidden'
            onMouseEnter={() => setIsShownLeft(true)}
            onMouseLeave={() => setIsShownLeft(false)}
          />
          <div className='group md:scale-75 lg:scale-100'>
            <img
              src={homeBox2}
              alt='home box'
              className='w-[334px] cursor-pointer group-hover:hidden'
            />
            <Link to={'/the-story'}>
              <img
                src={homeBoxActive}
                alt='home box'
                className='w-[334px] cursor-pointer hidden group-hover:inline'
              />
            </Link>
          </div>
          <img
            src={homeBox3}
            alt='home box'
            className='md:scale-75 lg:scale-100 w-[260px]'
            onMouseEnter={() => setIsShownRight(true)}
            onMouseLeave={() => setIsShownRight(false)}
          />
        </div>
      </div>
      <div className='flex flex-col justify-end h-screen pb-[33%] gap-2 md:hidden'>
        <Link to={'/the-story'}>
          <img
            src={bannerText}
            alt='bannerText'
            className='w-[235px] mx-auto'
          />
        </Link>
        <div className='flex gap-2 justify-center'>
          {/* <img src={aptos} alt='aptos' className='w-[85px]' /> */}
          {/* <a
            href='https://discord.gg/EmfCfBk4QS'
            target='_blank'
            rel='noreferrer'
          > */}
          <img src={discord} alt='discord' className='w-[85px]' />
          {/* </a> */}
          <a
            href='https://twitter.com/fauxarcade'
            target='_blank'
            rel='noreferrer'
          >
            <img src={twitter} alt='twitter' className='w-[85px]' />
          </a>
        </div>
      </div>
    </div>
  );
}
