import React, { useRef } from 'react';
import arcade1 from 'assets/new/arcade1.png';
import arcade2 from 'assets/new/arcade2.png';
import arcade3 from 'assets/new/arcade3.png';
import arcade4 from 'assets/new/arcade4.png';
import faq from 'assets/new/faq.png';
import minting from 'assets/new/minting.png';
import scrolldown from 'assets/new/scrolldown.png';
import mintingMobile from 'assets/new/minting-mobile.png';
import faqMobile from 'assets/new/faq-mobile.png';
import faqMask from 'assets/new/faq-mask.png';

const listArcade = [
  {
    img: arcade1,
    title: 'Daily Free Play in the Arcade',
  },
  {
    img: arcade2,
    title: 'Players buy $FAUX token to play Arcade to accumulate more $FAUX',
  },
  {
    img: arcade3,
    title: 'Fixed number of $FAUX token will be released each day',
  },
  {
    img: arcade4,
    title: 'Accumulate $FAUX to claim rewards',
  },
];

export default function Faq() {
  const myRef = useRef<null | HTMLImageElement>(null);

  const executeScroll = () => {
    if (myRef.current && myRef)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='md:bg-arcadebg bg-no-repeat bg-cover min-h-screen font-AnybodyBold md:px-[100px] mt-[50px] z-50'>
      <div className='bg-faqbgMobile1 bg-no-repeat bg-cover md:bg-none px-[40px] md:px-0 py-[80px] md:pt-0'>
        <div className='md:pt-[12%] 2xl:pt-[13%]'>
          <p className='md:text-center md:w-[80%] mx-auto text-[15px] md:text-[20px] text-justify'>
            First things first: if you're new to the world of $FAUX, let us
            explain how it works. The FauxArcade works by using the SOL token.
            You can buy $FAUX using SOL or earn them through playing games. Once
            you have enough $FAUX, you can redeem them for really cool prizes
            like a pretty kwool NFT at the Faux Marketplace.{' '}
            <p className='block mt-[10px] md:mt-0 md:inline'>
              It is a new way to win, and it's only available at FauxArcade.
            </p>
          </p>
        </div>
        <div className='mt-[35px] md:hidden' ref={myRef}>
          <img
            src={scrolldown}
            alt='scrolldown'
            className='mx-auto w-[31px]'
            onClick={executeScroll}
          />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-20 pt-[10%] md:pt-[4%] 2xl:mt-[7%] 2xl:max-w-[85%] mx-auto'>
          {listArcade.map((item, index) => (
            <div key={index} className='flex flex-col items-center'>
              <div className='md:h-[280px] w-[215px] flex items-end'>
                <img src={item.img} alt='arcade' className='w-full' />
              </div>
              <p className='mt-[33px] text-[20px] text-center leading-[25px] md:text-justify'>
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className='pt-[20%] md:px-[17%] bg-faqbgMobile2 bg-no-repeat bg-cover md:bg-none pb-[140px] px-[40px] relative'>
        <img
          src={faqMask}
          alt='faqMask'
          className='absolute w-full top-[-39px] left-0 md:hidden'
        />
        <div className='mt-[50px]'>
          <img src={faq} alt='faq' className='hidden md:inline' />
          <img src={faqMobile} alt='faq' className='md:hidden h-[38px]' />
        </div>
        <div className='mt-[70px] md:mt-[95px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            WHAT IS FAUX ARCADE
          </h1>
          <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
            Its the arcade of the future - similar to a traditional arcade but
            on-chain, Faux Arcade runs on $FAUX tokens that can be traded in for
            really dope prizes. Have a great time down at the arcade. If you
            know where to look…
          </p>
        </div>
        <div className='mt-[60px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            HOW DO I PLAY IN THE FAUX ARCADE?
          </h1>
          <ul className='text-[15px] md:text-[25px] leading-[23px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
            <li>1. Connect your wallet</li>
            <li>2. Buy a ticket</li>
            <li>3. Choose a game and game on</li>
            <li>4. Accumulate points</li>
            <li>5. Claim prizes using your saved up points</li>
          </ul>
        </div>
        <div className='mt-[60px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            WHAT GAMES WILL THE ARCADE HAVE?
          </h1>
          <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px] text-justify'>
            Faux Arcade compiles a variety of fun and easy-going mini games,
            enjoyable for everyone. New skill-based mini games will be
            progressively added to the arcade overtime.
          </p>
        </div>
        <div className='mt-[60px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            FAUX WELFARE
          </h1>
          <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
            Have your cake and get paid too! Stake your fauxes for profit at our
            in house staking service for those sweet benefits like free daily
            $FAUX tokens. Faux holders are entitled to profit sharing benefits
            generated from the arcade and our staking (SAAS) service.
          </p>
        </div>
        <div className='mt-[60px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            HOW MANY FAXUES ARE IN OUR SKULK?
          </h1>
          <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
            We're still filling up the den. If all the fauxes join forces on
            this one, we're going to have to move out of our burrows.
          </p>
        </div>
        <div className='mt-[100px] md:mt-[140px]'>
          <img src={minting} alt='minting' className='hidden md:inline' />
          <img
            src={mintingMobile}
            alt='minting'
            className='md:hidden h-[38px]'
          />
        </div>
        <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
          You're a little early.. hit up our twitter to keep you company while
          waiting for our mint announcement drop
        </p>

        <div className='mt-[60px]'>
          <h1 className='text-[25px] leading-[25px] md:leading-[36px] md:text-[40px]'>
            TOKEN OR WALLET-BASED MINT?
          </h1>
          <p className='text-[15px] md:text-[25px] leading-[17px] md:leading-[30px] text-[#2E005B] mt-[10px] md:mt-[20px]'>
            Wallet-based
          </p>
        </div>
      </div>
    </div>
  );
}
