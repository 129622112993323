import React, { useRef } from 'react';
import story from 'assets/new/story.png';
import arrow from 'assets/new/arrow-bottom.png';
import Slide from './Slide';
import bannerMobile from 'assets/new/story-banner-mobile.png';
import scrolldown from 'assets/new/scrolldown.png';

export default function Story() {
  const myRef = useRef<null | HTMLImageElement>(null);

  const executeScroll = () => {
    if (myRef.current && myRef)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='bg-storybgMobile md:bg-storybg bg-no-repeat bg-cover bg-center min-h-screen font-AnybodyBold'>
      <div className='pt-[150px] relative hidden md:block'>
        <img src={story} alt='story' className='w-full' />
        <Slide />
      </div>
      <div className='pt-[120px] md:hidden'>
        <img src={bannerMobile} alt='banner' className='mx-auto' />
      </div>
      <div className='mt-[47px] md:hidden'>
        <img
          src={scrolldown}
          alt='scrolldown'
          className='w-[31px] mx-auto'
          onClick={executeScroll}
        />
      </div>
      <div className='mx-auto justify-center my-5 mt-[70px] hidden md:flex'>
        <img
          src={arrow}
          alt='arrow'
          className='cursor-pointer'
          onClick={executeScroll}
        />
      </div>
      <div
        className='lg:w-[54%] px-10 md:px-5 py-[10%] mx-auto leading-[17px] text-[13px] md:text-[18px] md:leading-[25px] flex flex-col gap-10 text-justify'
        ref={myRef}
      >
        <p>
          KweKwek — the meloday of the fauxes blasts into the multiverse from
          the flashing portals at the arcade den. We fauxes live for the thrill
          and there’s no shortage of that at the arcade.
        </p>
        <p>
          We were vibing when sum wacky genius decided to throw us out and now
          these weird looking spaces with endless amounts of interlocking blocks
          and chains are what we call home. Bringing our new world order, we
          rampage through different dimensions, looting otherworldly creatures
          and nabbing these shiny tokens and weird looting boxes.
        </p>
        <p>
          These shiny tokens are more than shiny. Trading them back at the
          arcade fauxhole gets us sum’ really dope non-faux (legit) shiz. Like
          blueprints for new Universes to rampage, noice level upgrades and a
          really dope vending machine.
        </p>
        <p>
          It’s now up to us, no longer will we “Ring-ding-ding-dingeringerding”
          in the shadows but instead listen to the beautiful melody the KWEK
          makes.
        </p>
      </div>
      <img src={story} alt='story' className='w-full md:hidden' />
    </div>
  );
}
