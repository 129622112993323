import React, { useRef } from 'react';
import psp from '../../assets/new/psp.png';
import Slider from 'react-slick';
import slide1 from '../../assets/new/slide1.png';
import slide2 from '../../assets/new/slide2.png';
import slide3 from '../../assets/new/slide3.png';
import slide4 from '../../assets/new/slide4.png';
import slide5 from '../../assets/new/slide5.png';
import slide6 from '../../assets/new/slide6.png';
import slide0 from '../../assets/new/slide0.png';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export default function Slide() {
  const sliderRef = useRef(null);

  const gotoNext = () => {
    const sliderElement: any = sliderRef.current;
    if (sliderElement) sliderElement.slickNext();
  };

  const gotoPrev = () => {
    const sliderElement: any = sliderRef.current;
    if (sliderElement) sliderElement.slickPrev();
  };

  return (
    <div className='absolute left-0 right-0 mx-auto top-[25%]'>
      <div className='relative'>
        <img src={psp} alt='psp' className='mx-auto z-10 w-[60%]' />
        <Slider
          {...settings}
          ref={sliderRef}
          className='pl-[3px] w-[31.4%] pt-[1px] top-[18%] left-0 right-0 mx-auto'
        >
          <img src={slide0} alt='slide0' />
          <img src={slide6} alt='slide6' />
          <img src={slide1} alt='slide1' />
          <img src={slide2} alt='slide2' />
          <img src={slide3} alt='slide3' />
          <img src={slide4} alt='slide4' />
          <img src={slide5} alt='slide5' />
        </Slider>
        <div
          onClick={gotoNext}
          className='absolute top-[40%] left-[29.8%] w-[45px] h-[40px] cursor-pointer'
        ></div>
        <div
          onClick={gotoPrev}
          className='absolute top-[40%] left-[24.7%] w-[45px] h-[40px] cursor-pointer'
        ></div>
      </div>
    </div>
  );
}
